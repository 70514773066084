import { lazy, Suspense } from 'react';
import { createBrowserRouter, Link, Route, RouterProvider, Routes } from 'react-router-dom';
import Spinner from './Components/Spinner';
import Navbar from './Components/Navbar';
import ToastContainer from './Components/ToastContainer';
import Container from 'react-bootstrap/Container';
import './App.css';
import Root from './Pages/Root';
// import SearchPage, { loader as SearchPageLoader } from './Pages/SearchPage';
import AboutPage from './Pages/AboutPage';
import ErrorPage from './Pages/ErrorPage';

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    lazy: () => import('./Pages/SearchPage')
                },
                {
                    path: 'about',
                    element: <AboutPage />
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}

export default App;
