import Container from 'react-bootstrap/Container';
import NavBar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import logo from '../assets/logo.png';
import { NavLink, Link } from 'react-router-dom';

function Navbar() {
    const [theme, setTheme] = useState(window.localStorage.getItem('theme') || 'light');
    useEffect(() => {
        window.localStorage.setItem('theme', theme);
        console.log('Theme changed to', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((t) => (t === 'light' ? 'dark' : 'light'));
    };

    const themeIcon = theme === 'light' ? solid('moon') : solid('sun');

    return (
        <>
            <Helmet htmlAttributes={{ 'data-bs-theme': theme }} />
            <NavBar>
                <Container>
                    <NavBar.Brand as={Link} to="/">
                        <img
                            alt=""
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        Normify
                    </NavBar.Brand>
                    <NavBar.Toggle />
                    <NavBar.Collapse className="justify-content-end">
                        <Nav className="me-auto">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/">
                                    Home
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/about">
                                    About
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Button onClick={toggleTheme} aria-label="Toggle theme">
                            <FontAwesomeIcon icon={themeIcon} fixedWidth />
                        </Button>
                    </NavBar.Collapse>
                </Container>
            </NavBar>
        </>
    );
}

export default Navbar;
