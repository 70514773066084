import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AboutPage() {
    return (
        <Container>
            <Row>
                <h1>About the app</h1>
            </Row>
            <Row>
                <p>
                    This is a simple app that lists all the moroccan norms with an easy interface to
                    search and obtain the reference, tile, year and other relevant data even{' '}
                    <strong>offline</strong>.
                </p>
            </Row>
            <Row>
                <p className="lead">
                    Made with <span className="text-danger">♥</span> by{' '}
                    <a href="mailto:salah.eddine.amhil@gmail.com">Salah Eddine</a>
                </p>
            </Row>
        </Container>
    );
}
