import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from '../Components/Navbar';
import ToastContainer from '../Components/ToastContainer';

export default function Root() {
    return (
        <Container fluid className="App">
            <Navbar />
            <Outlet />
            <ToastContainer />
        </Container>
    );
}
